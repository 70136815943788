import React from "react";

export default function CookiesPolicy() {
  return (
    <div>
      {/* Page Title Section */}
        <section className="page-title-area sky-blue-bg pt-160 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">Understanding Cookies</h4>
                
                <h5 className="h1 page-title">Cookies Policy</h5>
                <p className="mb-4">
                  We use cookies to enhance your experience and improve our services. This policy explains what cookies are and how we use them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Cookies Policy Content */}
      <section className="cookies-policy-area py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="policy-content">
                <h3 className="fw-bold mb-4">What Are Cookies?</h3>
                <p className="text-muted mb-4">
                  Cookies are small text files placed on your device to improve your browsing experience and remember your preferences.
                </p>

                <h4 className="fw-bold mt-5 mb-3">1. Types of Cookies</h4>
                <ul className="text-muted list-unstyled mb-4">
                  <li>• <span className="fw-bold">Essential Cookies:</span> Required for website functionality.</li>
                  <li>• <span className="fw-bold">Analytics Cookies:</span> Help us track usage and improve performance.</li>
                  <li>• <span className="fw-bold">Preference Cookies:</span> Remember your settings and preferences.</li>
                  <li>• <span className="fw-bold">Advertising Cookies:</span> Deliver tailored ads.</li>
                </ul>

                <h4 className="fw-bold mt-5 mb-3">2. Managing Cookies</h4>
                <p className="text-muted mb-4">
                  You can manage or disable cookies through your browser settings. Note that disabling cookies may affect site functionality.
                </p>

                <h4 className="fw-bold mt-5 mb-3">3. Contact Us</h4>
                <p className="text-muted">
                  For any queries about cookies, email us at{" "}
                  <a href="mailto:cookies@yourcompany.com" className="text-primary fw-bold">
                    cookies@yourcompany.com
                  </a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
