import React, { useState, useEffect } from "react";
import userService from "../../../services/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmailNotifications = () => {
  const [notifications, setNotifications] = useState({
    login: false,
    tracking: false,
    shipment: false,
    updates: false,
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setLoading(true);
        const data = await userService.getUserNotifications();
        setNotifications({
          login: data.login_notifications,
          shipment: data.shipment_notifications,
          tracking: data.tracking_notifications,
          updates: data.shipperswise_updates,
        });
      } catch (error) {
        toast.error("Failed to load notification settings");
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleToggle = (key) => {
    setNotifications((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    const userNotifications = {
      login_notifications: notifications.login,
      shipment_notifications: notifications.shipment,
      tracking_notifications: notifications.tracking,
      shipperswise_updates: notifications.updates,
    };

    try {
      await userService.updateUserNotifications(userNotifications);
      toast.success("Email notification settings updated successfully");

      const updatedSettings = await userService.getUserNotifications();
      setNotifications({
        login: updatedSettings.login_notifications,
        shipment: updatedSettings.shipment_notifications,
        tracking: updatedSettings.tracking_notifications,
        updates: updatedSettings.shipperswise_updates,
      });
    } catch (error) {
      toast.error("Failed to update email notification settings");
      console.error("Error updating notifications:", error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="email-notifications">
      <ToastContainer />
      <div className="dashboard-title">
        <h1 className="page-title">Edit Email Notification Settings</h1>
        <span>
          The page allows you to select what we send to you via email.
        </span>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="notification-card">
          {loading ? (
            // Skeleton loader
            <div className="skeleton-loader">Loading settings...</div>
          ) : (
            <>
              <NotificationItem
                title="Login Notifications"
                description="Receive an email notification for each login to your account for added security and monitoring."
                checked={notifications.login}
                onToggle={() => handleToggle("login")}
              />
              <NotificationItem
                title="Tracking Notifications"
                description="Get notified via email about updates on the status and location of your shipments for real-time tracking information."
                checked={notifications.tracking}
                onToggle={() => handleToggle("tracking")}
              />
              <NotificationItem
                title="Shipment Notifications"
                description="Receive email notifications for updates regarding your shipments, including order confirmation, shipping status changes, and delivery notifications."
                checked={notifications.shipment}
                onToggle={() => handleToggle("shipment")}
              />
              <NotificationItem
                title="Shipperswise Updates"
                description="Get notified via email about updates and new features on this platform."
                checked={notifications.updates}
                onToggle={() => handleToggle("updates")}
              />
              <button
                className="theme_btn rounded-1"
                type="submit"
                disabled={updating}
              >
                {updating ? "Updating Settings..." : "Update Settings"}
              </button>
            </>
          )}
        </div>
      </form>
      <style jsx>{`
        .email-notifications {
          font-family: Arial, sans-serif;
        }
        header {
          background-color: #2196f3;
          color: white;
          padding: 20px;
          border-radius: 5px 5px 0 0;
        }

        header p {
          margin: 10px 0 0;
          font-size: 18px;
        }
        .notification-card {
          background-color: white;
          border-radius: 0 0 5px 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          padding: 20px;
        }
        .notification-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          border-bottom: 1px solid #e0e0e0;
        }
        .notification-item:last-child {
          border-bottom: none;
        }
        h2 {
          font-size: 18px;
          margin: 0 0 10px;
        }
        p {
          font-size: 16px;
          color: #666;
          margin: 0;
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 58px;
          height: 30px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 34px;
        }
        .slider:before {
          position: absolute;
          content: "";
          height: 22px;
          width: 22px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
        input:checked + .slider {
          background-color: #2196f3;
        }
        input:checked + .slider:before {
          transform: translateX(26px);
        }
        button {
          margin-top: 20px;
        }
        button:hover {
          background-color: #1976d2;
        }
      `}</style>
    </div>
  );
};

// Notification item component
const NotificationItem = ({ title, description, checked, onToggle }) => (
  <div className="notification-item">
    <div style={{width: "75%"}}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={onToggle} />
      <span className="slider"></span>
    </label>
  </div>
);

export default EmailNotifications;
