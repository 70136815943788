import React from "react";
import { Link } from "react-router-dom";
import "./NewsletterModal.css"; // Optional: Import CSS for styling the modal

const NewsletterModal = ({ show, onClose }) => {

  if (!show) {
    return null;
  }
  
  // const handleLogin = () => {
  //   navigate("/login");
  //   onClose();
  // };

  // const handleCreateAccount = () => {
  //   navigate("/signup");
  //   onClose();
  // };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <div>
          <h2>Subscribe to Newsletter</h2>
          <p>Subscribe to our newsletter for new and exclusive offers.</p>
        </div>

         <div className="footer__widget mb-30">
                      {/* <h4 className="widget-title mb-30">Newsletter</h4>
                      <span className="address-details mb-25">
                        Subscribe to our newsletter for new and exclusive offers.
                      </span> */}
                      <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                        <input
                          type="checkbox"
                          id="acceptPolicy"
                          name="acceptPolicy"
                          // checked={formData.acceptPolicy}
                          // onChange={handleCheckChange}
                          style={{ display: "inline" }}
                        />
                        <label
                          htmlFor="acceptPolicy"
                          style={{ marginLeft: "5px", display: "inline" }}
                        >
                          I confirm that I have read, consent and agree to ShippersWise{" "}
                          <Link href="#" style={{ textDecoration: "underline" }}>
                            Terms of service
                          </Link>{" "}
                          and{" "}
                          <Link href="#" style={{ textDecoration: "underline" }}>
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                             
                      <div className="footer-layout-2 mt-25">
                        <form className="subscribe-form mb-1" action="">
                          <div className="input-wrapper">
                            <input type="text" placeholder="Email address" />
                            <button className="footer_sub_btn">
                              <img
                                src="/assets/img/icon/right-arrow.svg"
                                alt="Right Arrow"
                              />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

        {/* <div className="auth-modal-buttons">
          <button
            className="theme_btn_border login"
            style={{ fontSize: "24px" }}
            onClick={handleLogin}
          >
            Log In
          </button>
          <button className="theme_btn" onClick={handleCreateAccount}>
            Create an Account
          </button>
        </div> */}
        <button className="close-modal" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default NewsletterModal;
