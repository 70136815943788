import React, { useState } from "react";
import Select from "../Select";
import Input from "../Input";
import "./stepper.css";
import CountrySelect from "../CountrySelect";
import { preferredCurrency } from "../../../Data/PrefferredCurrency";

const paymentMethodData = ["Credit/Debit Card "];
const typeofRelocation = [
  "Home relocation",
  "Office relocation",
  "Store delivery",
  "Interstate car transport",
  "Junk removal",
  "Wedding gifts",
  "International relocation",
];

export default function RelocationForm({
  handleSubmit,
  formData,
  setFormData,
}) {
  const [step, setStep] = useState(1);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const validateStep = (currentStep) => {
    const newErrors = {};
    const requiredFields = {
      1: [
        "typeOfRelocation",
        "listOfItems",
        "preferredMovingDate",
        "preferredCurrency",
        "paymentMethod",
      ],
      2: ["countryFrom", "cityFrom", "postalCodeFrom", "addressFrom"],
      3: ["countryTo", "cityTo", "postalCodeTo", "addressTo"],
      
    };

    requiredFields[currentStep].forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    console.log(errors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (validateStep(step)) {
      handleSubmit(formData);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h4 className="mt-2 mb-4">Package Details</h4>

            <div className="col-lg-12 mb-10">
              <div className="origin-place ">
                <div className="me-4">
                  <p>List of Items to Move</p>
                  <textarea
                    name="listOfItems"
                    onChange={handleChange}
                    value={formData.listOfItems || ""}
                  />
                </div>
                {errors.listOfItems && (
                    <span className="error">{errors.listOfItems}</span>
                  )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="from-place mb-30">
                <div className="origin-place mb-10" style={{ zIndex: "10000" }}>
                  <p>
                    Type of Relocation{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Select
                    data={typeofRelocation}
                    name="typeOfRelocation"
                    onChange={handleChange}
                    value={formData.typeOfRelocation || ""}
                  />
                  {errors.typeOfRelocation && (
                    <span className="error">{errors.typeOfRelocation}</span>
                  )}
                </div>

                <div className="origin-place mb-10">
                  <p>
                    Preferred Moving Date{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="date"
                    name="preferredMovingDate"
                    onChange={handleChange}
                    value={formData.preferredMovingDate || ""}
                  />
                  {errors.preferredMovingDate && (
                    <span className="error">{errors.preferredMovingDate}</span>
                  )}
                </div>

                <div className="origin-place mb-10">
                  <p>
                    Preferred Currency{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Select
                    data={preferredCurrency}
                    name="preferredCurrency"
                    onChange={handleChange}
                    value={formData.preferredCurrency || ""}
                  />
                  {errors.preferredCurrency && (
                    <span className="error">{errors.preferredCurrency}</span>
                  )}
                </div>
                <div className="origin-place mb-10">
                  <p>
                    Payment Method{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Select
                    data={paymentMethodData}
                    name="paymentMethod"
                    onChange={handleChange}
                    value={formData.paymentMethod || ""}
                  />
                  {errors.paymentMethod && (
                    <span className="error">{errors.paymentMethod}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="origin-place ">
                  <div className="me-4">
                    <p>Additional Info</p>
                    <textarea
                      name="additionalInfo"
                      onChange={handleChange}
                      value={formData.additionalInfo || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <div className="row ">
            <h4 className="mt-2 mb-4">Moving from</h4>
            <div className="col-lg-12 ">
              <div className="from-place mb-30 gap-3">
                <div className="origin-place ">
                  <p>
                    Country{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>

                  <CountrySelect
                    value={formData.countryFrom || ""}
                    name="countryFrom"
                    onChange={handleChange}
                  />
                  {errors.countryFrom && (
                    <span className="error">{errors.countryFrom}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">City</p>
                  <Input
                    type="text"
                    name="cityFrom"
                    onChange={handleChange}
                    value={formData.cityFrom || ""}
                  />
                  {errors.cityFrom && (
                    <span className="error">{errors.cityFrom}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Postal Code{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="postalCodeFrom"
                    onChange={handleChange}
                    value={formData.postalCodeFrom || ""}
                  />
                  {errors.postalCodeFrom && (
                    <span className="error">{errors.postalCodeFrom}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Street{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="addressFrom"
                    onChange={handleChange}
                    value={formData.addressFrom || ""}
                  />
                  {errors.addressFrom && (
                    <span className="error">{errors.addressFrom}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="row align-items-center">
            <h4 className="mt-2 mb-4">Moving to</h4>

            <div className="col-lg-12 ">
              <div className="from-place mb-30 gap-3">
                <div className="origin-place ">
                  <p>
                    Country
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <CountrySelect
                    name="countryTo"
                    value={formData.countryTo || ""}
                    onChange={handleChange}
                  />
                  {errors.countryTo && (
                    <span className="error">{errors.countryTo}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    City
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="cityTo"
                    onChange={handleChange}
                    value={formData.cityTo || ""}
                  />
                  {errors.cityTo && (
                    <span className="error">{errors.cityTo}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Postal Code
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="postalCodeTo"
                    onChange={handleChange}
                    value={formData.postalCodeTo || ""}
                  />
                  {errors.postalCodeTo && (
                    <span className="error">{errors.postalCodeTo}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Street
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="addressTo"
                    onChange={handleChange}
                    value={formData.addressTo || ""}
                  />
                  {errors.addressTo && (
                    <span className="error">{errors.addressTo}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderStepper = () => {
    return (
      <div className="stepper">
        {[1, 2, 3].map((stepNum) => (
          <span
            key={stepNum}
            className={`step ${step >= stepNum ? "active" : ""}`}
          ></span>
        ))}
      </div>
    );
  };

  return (
    <div className="quote-info-wrapper">
      {renderStepper()}
      {renderStep()}
      <div className="row align-items-center">
        <div className="col-6 col-lg-6 col-md-6">
          {step > 1 && (
            <div className="total-count-box mb-30 mt-30">
              <button
                className="theme_btn black-btn rounded-1"
                onClick={handlePrevious}
              >
                Previous
              </button>
            </div>
          )}
        </div>
        <div className="col-6 col-lg-6 col-md-6 text-end">
          <div className="total-count-box mb-30 mt-30 mr-20">
            {step < 3 ? (
              <button
                className="theme_btn black-btn rounded-1"
                onClick={handleNext}
              >
                Next
              </button>
            ) : (
              <button
                className="theme_btn black-btn rounded-1"
                onClick={(e) => handleSubmitForm(e)}
              >
                Request a Quote
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
