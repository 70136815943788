import React from "react";

const FAQ = () => {
  return (
    <section
      className="copic-faq pt-150 pb-80 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30"
      style={{
        backgroundImage: 'url("assets/img/faq/faq-bg-1.png")',
      }}
    >
      <img
        className="faq-shape faq-two"
        src="assets/img/faq/faq-round-2.svg"
        alt="Faq Shape"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="faq-wrapper mb-30">
              <div className="section-title section-title2 mb-35">
                <h2 className="mb-35">Frequently Asked Questions</h2>
                <p>
                  We provide adaptable and speedy shipping solutions, managing
                  your entire shipping process seamlessly. We help you to gain
                  visibility and control over your shipping chain with instant
                  quotes, easy bookings, and efficient tracking.
                </p>
              </div>
              <ul className="text-list list-style-none">
                <li>
                  We're on a mission is to revolutionize international freight
                  by providing adaptable and fast shipping solutions.
                </li>
                <li>Our services are efficient and very affordable.</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="faq-que-list mb-30">
              <div className="accordion" id="accordionExample">
                {faqData.map((faq, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index + 1}`}>
                      <button
                        className={`accordion-button ${
                          faq.open ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index + 1}`}
                        aria-expanded={faq.open}
                        aria-controls={`collapse${index + 1}`}
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index + 1}`}
                      className={`accordion-collapse collapse ${
                        faq.open ? "show" : ""
                      }`}
                      aria-labelledby={`heading${index + 1}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>{faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const faqData = [
  {
    question: "01. How can I track my shipment?",
    answer:
      "You can easily track your shipment by entering your tracking number on our website's tracking page. Once entered, you will get updates on the status and location of your shipment.",
    open: true,
  },
  {
    question: "02. What shipping options do you offer?",
    answer:
      "We offer a variety of shipping options to suit your needs, including standard, expedited, and express shipping. You can choose the option that best fits your budget and delivery timeline.",
    open: false,
  },
  {
    question: "03. How long will it take for my package to arrive?",
    answer:
      "The delivery time depends on the shipping option you choose and the destination of your package. Standard shipping typically takes 3 days, while expedited and express shipping are faster.",
    open: false,
  },
  {
    question: "04. What happens if my package is lost or damaged?",
    answer:
      "In the rare event that your package is lost or damaged during transit, we will work with our shipping partners to resolve the issue and ensure you receive a replacement or refund as soon as possible.",
    open: false,
  },
];

export default FAQ;
