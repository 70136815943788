import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import MainComponent from "./components/Main";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import QuoteForm from "./components/QuoteForm/QuoteForm";
import ScrollToTop from "./components/ui/ScrollToTop";
import TrackParcels from "./components/TrackParcel/TrackParcels";
import LoginPage from "./components/Auth/LoginPage";
import SignUp from "./components/Auth/SignUp";
import Service from "./components/AllService/Service";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Layout from "./Dashboard/Users/Layout";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import QuotationPage from "./Dashboard/Users/Components/QuotationPage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import Dashboard from "./Dashboard/Users/Pages/Dashboard";
import GetQuotes from "./Dashboard/Users/Pages/GetQuotes";
import ParcelTracking from "./Dashboard/Users/Pages/ParcelTracking";
import Profile from "./Dashboard/Users/Pages/Profile";
import EmailNotifications from "./Dashboard/Users/Pages/EmailNotification";
import ChangePassword from "./Dashboard/Users/Pages/ChangePassword";
import Shipment from "./Dashboard/Users/Pages/Shipment";
import Quotes from "./Dashboard/Users/Pages/Quotes";
import Scanner from "./components/Scanner/Scanner";
import Blog from "./pages/Blog";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";

// Separate component for the app content that needs access to location
function AppContent() {
  const location = useLocation();
  const noHeaderFooterRoutes = [
    "/login",
    "/signup",
    "/forgot-password",
    "/dashboard",
    "/quotation",
    "/scanner",
  ];

  const shouldRenderHeaderFooter = !noHeaderFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div className="App">
      <ScrollToTop />
      {shouldRenderHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<MainComponent />} />
        <Route path="/quote/:service" element={<QuoteForm />} />
        <Route path="/trackparcels" element={<TrackParcels />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/all-services" element={<Service />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/quotation/:quotationId" element={<QuotationPage />} />
        <Route path="/scanner" element={<Scanner />} />
        <Route path="/cookie-policy" element={<CookiesPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="get-quotes" element={<GetQuotes />} />
            <Route path="parcel-tracking" element={<ParcelTracking />} />
            <Route path="profile" element={<Profile />} />
            <Route path="email-notification" element={<EmailNotifications />} />
            <Route path="change-password" element={<ChangePassword />} />

            {/* Shipment Routes */}
            <Route path="all-shipments" element={<Shipment type="all" />} />
            <Route
              path="pending-shipments"
              element={<Shipment type="pending" />}
            />
            <Route
              path="shipments-in-transit"
              element={<Shipment type="shipments-in-transit" />}
            />
            <Route
              path="delivered-shipments"
              element={<Shipment type="delivered" />}
            />
            <Route
              path="failed-shipments"
              element={<Shipment type="failed" />}
            />
            <Route
              path="declined-shipments"
              element={<Shipment type="declined" />}
            />

            {/* Quotation Routes */}

            <Route
              path="unpaid-quotations"
              element={<Quotes type="unpaid" />}
            />
            <Route path="paid-quotations" element={<Quotes type="paid" />} />
            <Route
              path="declined-quotations"
              element={<Quotes type="declined" />}
            />
            <Route path="all-quotations" element={<Quotes type="all" />} />
          </Route>
        </Route>
      </Routes>
      {shouldRenderHeaderFooter && <Footer />}
    </div>
  );
}

// Main App component that provides the Router context
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
