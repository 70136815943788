import React from "react";
import ServiceCards from "./ServiceCards";
import seaImg from "../../assets/services-imgs/Freight-Ship.jpg"
import courierImg from "../../assets/services-imgs/Courier.jpg"
import airImg from "../../assets/services-imgs/Freight-plane.jpg"
import railImg from "../../assets/services-imgs/Freight-Train.jpg"
import roadImg from "../../assets/services-imgs/Road-Transport.jpg"
import relocationImg from "../../assets/services-imgs/Relocation.jpg"


const SERVICES = [
  {
    id: 1,
    title: "courier",
    description: `We are your e-market place for shipping documents, parcels and
            pallets, saving you time and cost on price comparison on multiple
            courier service providers. With us, one request gives you multiple
            solutions.`,
    icon: courierImg,
    service: "courier",
  },
  {
    id: 2,
    title: "Air Freight",
    description: `We aim to provide global Air freight quotes at the touch of a button, 
    enabling you to send your cargo with shorter transit times using trusted partners. Cargoes range from heavy machinery, furniture, electronics, food items and much more. Whatever it is, ShippersWise is here for you.`,
    icon: airImg,
    service: "air-freight",
  },
  {
    id: 3,
    title: "sea freight",
    description: `We solve the complexities of international ocean freights which SME's and individuals face by bringing global logistics partners to your virtual doorstep. Get your transparent full container load (FCL) and less than container load (LCL) sea freight quotes on all sailings from us.`,
    icon: seaImg,
    service: "sea-freight",
  },
  {
    id: 4,
    title: "Rail Freight",
    description: `We are very well suited to get our customers the best rail freight quotes, saving costs and planning the best routes for each shipment.`,
    icon: railImg,
    service: "rail-freight",
  },
  {
    id: 5,
    title: "Road transport",
    description: `We give you access to transparent and direct contacts to our major partners by providing door-to-door delivery by air, sea, and land by optimizing cost, route, and transit times efficiently.`,
    icon: roadImg,
    service: "road-transport",
  },
  {
    id: 6,
    title: "relocation",
    description: `We have a wide range of service plans to choose from. Our one-stop service offers you a bespoke service which makes relocation easier, safer and more flexible, getting your belongings to the destination of your choice.`,
    icon: relocationImg,
    service: "relocation",
  },
];

const Service = () => {
  return (
    <div className="container service-content">
      <h2 className="text-center mb-4">All Services</h2>
      <div className="row g-4">
        {SERVICES.map((item) => (
          <div className="col-12 col-md-6 col-lg-4" key={item.id}>
            <ServiceCards
              title={item.title}
              img={item.icon}
              service={item.service}
            >
              {item.description}
            </ServiceCards>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
