import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <>
      {/* Hero Section */}
      <section className="page-title-area sky-blue-bg pt-160 pb-100" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center text-white">
                <h4 className="styled-text theme-color mb-3">Know more about us</h4>
                <h5 className="h1 page-title mb-5">About Us</h5>
                <p className="lead ">We are committed to providing excellent services to our clients and ensuring customer satisfaction through innovation and care.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Content Section */}
      <section className="about-us-content py-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-4">
              <div className="about-card shadow-lg p-4 mb-4" style={{ borderRadius: "10px", backgroundColor: "#ffffff", transition: "transform 0.3s ease-in-out" }}>
                <h3 className="mb-3">Our Mission</h3>
                <p className="text-muted">To provide high-quality products and services that meet the evolving needs of our customers, while maintaining a strong ethical foundation.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-card shadow-lg p-4 mb-4" style={{ borderRadius: "10px", backgroundColor: "#ffffff", transition: "transform 0.3s ease-in-out" }}>
                <h3 className="mb-3">Our Vision</h3>
                <p className="text-muted">To be a global leader in our industry, driving innovation and excellence in everything we do.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-card shadow-lg p-4 mb-4" style={{ borderRadius: "10px", backgroundColor: "#ffffff", transition: "transform 0.3s ease-in-out" }}>
                <h3 className="mb-3">Our Values</h3>
                <p className="text-muted">Integrity, innovation, and excellence form the core of our business practices, ensuring that we always put our customers first.</p>
              </div>
            </div>
          </div>

          <div className="row text-center mt-5">
            <div className="col-lg-6">
              <h3 className="fw-bold">Why Choose Us?</h3>
              <p className="text-muted mb-4">We bring passion and dedication to everything we do, backed by years of expertise and a focus on customer satisfaction.</p>
            </div>
            <div className="col-lg-6">
              <h3 className="fw-bold">Our Team</h3>
              <p className="text-muted">Our diverse and talented team is dedicated to making a difference. Together, we work to bring your ideas to life.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section text-white py-5" style={{background: "#000066"}}>
        <div className="container text-center">
          <h3 className="mb-4 text-white">Ready to ship with us?</h3>
          <p className="lead mb-4 text-light">Let’s make your next shipment aamazing. Contact us now and let's start your quote!</p>
          <Link to="/contact-us" className="btn btn-secondary btn-lg" style={{background: "#fff", color: "#000066", fontWeight: "600"}}>Get In Touch</Link>
        </div>
      </section>
    </>
  );
}
