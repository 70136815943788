import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function IntInput({ onChange, value, name, ...props }) {
  const [phoneNumber, setPhoneNumber] = useState(value || "");

  useEffect(() => {
    setPhoneNumber(value || "");
  }, [value]);

  const handleChange = (number, country) => {
    setPhoneNumber(number);
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: number,
          country: country,
        },
      });
    }
  };

  return (
    <div
      className="quantity q-style1 me-4 mb-1"
      style={{ position: "relative", zIndex: "100" }}
    >
      <PhoneInput
        country={"us"}
        value={phoneNumber}
        onChange={handleChange}
        inputClass="custom-phone-input w-100"
        containerClass="intl-tel-input w-100"
        {...props}
      />
    </div>
  );
}
