import { MailQuestion, Phone, MapPin, MessageSquare, MessageCircle } from "lucide-react";
import React from "react";

const ContactUs = () => {
  const contactDetails = [
    {
      title: "General Enquiries",
      description: "For general questions or concerns, email us at:",
      email: "info@shipperswise.com",
      icon: <MailQuestion size={50} />, 
    },
    {
      title: "Advert Opportunities",
      description: "Reach us for advertising opportunities at:",
      email: "enquiry@shipperswise.com",
      icon: <MessageSquare size={50} />, 
    },
    {
      title: "Media Enquiries",
      description: "For media inquiries or interview requests:",
      email: "media@shipperswise.com",
      icon: <Phone size={50} />, 
    },
    {
      title: "WhatsApp",
      description: "Reach out to us directly on WhatsApp:",
      email: "https://bit.ly/AdmTeam01",
      icon:  <MessageCircle size={50} />, 
    },
    {
      title: "Physical Address",
      description: "Visit us at our office:",
      email: "Gallans Town Road, Dublin, Ireland",
      icon: <MapPin size={50} />, 
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <section className="page-title-area sky-blue-bg pt-160 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">Have Questions?</h4>
                <h5 className="h1 page-title">Contact us</h5>
                 <p className="lead text-muted">
            We're here to help. Reach out to us via the channels below.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Cards Section */}
      <section className="contact-cards py-5">
        <div className="container">
          <div className="row gy-4">
            {contactDetails.map((item, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div
                  className="card border-0 shadow-sm h-100 text-center p-4"
                  style={{
                    backgroundColor: item.backgroundColor,
                    borderRadius: "10px",
                  }}
                >
                  <div className="icon mb-3">
                    {item.icon}
                  </div>
                  <h5 className="card-title fw-bold mb-2">{item.title}</h5>
                  <p className="card-text text-muted">{item.description}</p>
                  <a
                    href={item.email.startsWith("http") ? item.email : `mailto:${item.email}`}
                    className="text-decoration-none text-primary fw-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.email}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
