import React, { useState, useEffect } from "react";
import { Package, Plane, Ship, Train, Truck, Home } from "lucide-react";
import { useNavigate } from "react-router-dom";
import "./Solutions.css";
import seaImg from "../../assets/services-imgs/Freight-Ship.jpg"
import courierImg from "../../assets/services-imgs/Courier.jpg"
import airImg from "../../assets/services-imgs/Freight-plane.jpg"
import railImg from "../../assets/services-imgs/Freight-Train.jpg"
import roadImg from "../../assets/services-imgs/Road-Transport.jpg"
import relocationImg from "../../assets/services-imgs/Relocation.jpg"

const Solutions = () => {
  const [selectedTab, setSelectedTab] = useState("Courier");
  const [loadedImages, setLoadedImages] = useState({});
  const navigate = useNavigate();

  const handleServiceClick = () => {
    navigate(`/quote/${selectedTab.toLowerCase().replace(/\s+/g, "-")}`);
  };

  const tabs = [
    {
      title: "Courier",
      subtitle: "Delivers packages quickly and securely.",
      icon: Package,
      imagePath: courierImg
    },
    {
      title: "Air Freight",
      subtitle: "Transports goods swiftly by air.",
      icon: Plane,
      imagePath: airImg
    },
    {
      title: "Sea Freight",
      subtitle: "Ships large cargo across oceans.",
      icon: Ship,
      imagePath: seaImg
    },
    {
      title: "Rail Freight",
      subtitle: "Transports heavy goods long distances.",
      icon: Train,
      imagePath: railImg
    },
    {
      title: "Road Transport",
      subtitle: "Move goods by land efficiently.",
      icon: Truck,
      imagePath: roadImg
    },
    {
      title: "Relocation",
      subtitle: "Relocation services.",
      icon: Home,
      imagePath: relocationImg
    }
  ];

  // Preload all images
  useEffect(() => {
    tabs.forEach(tab => {
      const img = new Image();
      img.src = tab.imagePath;
      img.onload = () => {
        setLoadedImages(prev => ({
          ...prev,
          [tab.title]: true
        }));
      };
    });
  });

  const tabContent = {
    Courier: {
      header: "Courier",
      text: "We are your e-market place for shipping documents, parcels, and pallets, saving you time and cost on price comparison across multiple courier services. With us, one request gives you multiple solutions."
    },
    "Air Freight": {
      header: "Air Freight",
      text: "We aim to provide global Air freight quotes at the touch of a button, enabling you to send your cargo with shorter transit times using trusted partners."
    },
    "Sea Freight": {
      header: "Sea Freight",
      text: "We solve the complexities of international ocean freights by bringing global logistics partners to your virtual doorstep."
    },
    "Rail Freight": {
      header: "Rail Freight",
      text: "We are well suited to get our customers the best rail freight quotes, saving costs and planning the best routes for each shipment."
    },
    "Road Transport": {
      header: "Road Transport",
      text: "We provide access to direct contacts with our partners by offering door-to-door delivery by air, sea, and land."
    },
    Relocation: {
      header: "Relocation",
      text: "We offer a bespoke service that makes relocation easier, safer, and more flexible, getting your belongings to your destination."
    }
  };

  return (
    <div className="container mt-5">
      {/* Header Section */}
      <div className="row justify-content-center mb-5">
        <div className="col-xl-7 col-lg-8 col-md-10">
          <div className="text-center">
            <h2 className="mb-3">Our Solutions</h2>
            <p className="text-muted">
              Discover a new era of international freight with Shipperswise.
            </p>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="row">
        <div className="col-12">
          <div className="tab-header position-relative mb-5">
            <div className="horizontal-scroll">
              <div className="d-flex justify-content-start justify-content-md-center" 
                   style={{ borderBottom: "2px solid #D5DADF", overflowX: "auto", WebkitOverflowScrolling: "touch" }}>
                {tabs.map((tab) => (
                  <button
                    key={tab.title}
                    onClick={() => setSelectedTab(tab.title)}
                    className={`tab-button d-flex flex-column align-items-center justify-content-center px-3 px-md-4 py-2 mx-2 mx-md-3 border-0 bg-transparent ${
                      selectedTab === tab.title ? "active" : ""
                    }`}
                    style={{
                      minWidth: "80px",
                      borderBottom: selectedTab === tab.title ? "4px solid #000067" : "3px solid transparent",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <tab.icon
                      className={`mb-2 ${
                        selectedTab === tab.title ? "#000067" : "text-secondary"
                      }`}
                      size={24}
                    />
                    <span className={`small ${
                      selectedTab === tab.title ? "#000067" : "text-secondary"
                    }`}>
                      {tab.title}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="row align-items-center justify-content-between gy-4 pb-50">
        {/* Image Column */}
        <div className="col-12 col-md-6 position-relative">
          <div className="image-container" style={{ position: 'relative', paddingTop: '66.67%', overflow: 'hidden' }}>
            {!loadedImages[selectedTab] && (
              <div className="placeholder-glow w-100 h-100 position-absolute top-0 start-0">
                <div className="placeholder w-100 h-100"></div>
              </div>
            )}
            {tabs.map((tab) => (
              <img
                key={tab.title}
                src={tab.imagePath}
                alt={tab.title}
                className={`position-absolute top-0 start-0 w-100 h-100 rounded shadow-sm`}
                style={{
                  objectFit: 'contain',
                  opacity: selectedTab === tab.title ? 1 : 0,
                  transition: 'opacity 0.3s ease-in-out',
                  visibility: selectedTab === tab.title ? 'visible' : 'hidden'
                }}
              />
            ))}
          </div>
        </div>

        {/* Content Column */}
        <div className="col-12 col-md-6">
          <div className="ps-md-4">
            <h3 className="mb-3">{tabContent[selectedTab].header}</h3>
            <p className="mb-4 text-muted">{tabContent[selectedTab].text}</p>
            <button
              className="theme_btn"
              onClick={handleServiceClick}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;