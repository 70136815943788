import React from "react";

export default function TermsAndConditions() {
  return (
    <div>
      {/* Page Title Section */}
      <section className="page-title-area sky-blue-bg pt-160 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">Need More Information?</h4>
                
                <h5 className="h1 page-title">Terms and Conditions</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Terms and Conditions Section */}
      <section className="terms-conditions-area py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-content">
                <h3 className="mb-4">Welcome to ShippersWise</h3>
                <p>
                  These Terms and Conditions govern your use of our website and services. By accessing or using our services, you agree to these terms. If you do not agree, please discontinue use immediately.
                </p>

                <h4 className="mt-5 mb-3">1. Using Our Services</h4>
                <p>
                  Our services are available to individuals who are at least [age] years old. You agree not to misuse our services or engage in unauthorized activities such as hacking, reverse engineering, or distributing harmful content.
                </p>

                <h4 className="mt-5 mb-3">2. Account Responsibility</h4>
                <p>
                  When creating an account, you must provide accurate information. You are responsible for safeguarding your account credentials and all activities performed under your account. Notify us immediately of any unauthorized use.
                </p>

                <h4 className="mt-5 mb-3">3. Intellectual Property</h4>
                <p>
                  All content provided on this site, including but not limited to text, graphics, and logos, is the exclusive property of [Your Company Name]. You may not reproduce, distribute, or exploit our content without written consent.
                </p>

                <h4 className="mt-5 mb-3">4. Privacy and Data Use</h4>
                <p>
                  Our use of your data is governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our services, you agree to this policy.
                </p>

                <h4 className="mt-5 mb-3">5. Termination</h4>
                <p>
                  We reserve the right to terminate or suspend access to our services without prior notice if you violate these Terms and Conditions or engage in activities that harm our platform or users.
                </p>

                <h4 className="mt-5 mb-3">6. Limitation of Liability</h4>
                <p>
                  To the fullest extent permitted by law, [Your Company Name] is not liable for any indirect, incidental, or consequential damages arising from your use of our services.
                </p>

                <h4 className="mt-5 mb-3">7. Updates to Terms</h4>
                <p>
                  We may modify these Terms and Conditions from time to time. The most recent version will always be available on this page, and continued use of our services constitutes acceptance of the updated terms.
                </p>

                <h4 className="mt-5 mb-3">8. Contact Information</h4>
                <p>
                  If you have any questions or concerns about these Terms and Conditions, please contact us at{" "}
                  <a href="mailto:support@yourcompany.com">support@yourcompany.com</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
