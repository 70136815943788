// import React from "react";

// export default function PrivacyPolicy() {
//   return (
//     <div>
//       {/* Page Title Section */}
//       <section className="page-title-area sky-blue-bg pt-160 pb-100">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-xl-6">
//               <div className="page-title-wrapper text-center">
//                 <h4 className="styled-text theme-color mb-30">Your Privacy Matters</h4>
//                 <p className="mb-4">
//                   At [Your Company Name], we are committed to protecting your privacy. Learn more about how we collect, use, and safeguard your personal information.
//                 </p>
//                 <h5 className="h1 page-title">Privacy Policy</h5>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Privacy Policy Section */}
//       <section className="privacy-policy-area py-5">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="policy-content">
//                 <h3 className="mb-4">Introduction</h3>
//                 <p>
//                   This Privacy Policy outlines how [Your Company Name] collects, uses, and protects your personal information when you use our website or services.
//                 </p>

//                 <h4 className="mt-5 mb-3">1. Information We Collect</h4>
//                 <p>
//                   We may collect personal information, such as your name, email address, and payment details, as well as non-personal data like browser type and IP address.
//                 </p>

//                 <h4 className="mt-5 mb-3">2. How We Use Your Information</h4>
//                 <p>
//                   Your data is used to provide and improve our services, process payments, send notifications, and comply with legal obligations.
//                 </p>

//                 <h4 className="mt-5 mb-3">3. Sharing Your Information</h4>
//                 <p>
//                   We do not sell or rent your personal information. However, we may share data with trusted third parties for business operations, compliance, or when required by law.
//                 </p>

//                 <h4 className="mt-5 mb-3">4. Data Security</h4>
//                 <p>
//                   We implement robust security measures to protect your data. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.
//                 </p>

//                 <h4 className="mt-5 mb-3">5. Your Rights</h4>
//                 <p>
//                   You have the right to access, update, or delete your personal data. To exercise these rights, please contact us at{" "}
//                   <a href="mailto:privacy@yourcompany.com">privacy@yourcompany.com</a>.
//                 </p>

//                 <h4 className="mt-5 mb-3">6. Updates to This Policy</h4>
//                 <p>
//                   We may revise this Privacy Policy periodically. The latest version will always be available on this page.
//                 </p>

//                 <h4 className="mt-5 mb-3">7. Contact Information</h4>
//                 <p>
//                   For any privacy-related inquiries, please contact us at{" "}
//                   <a href="mailto:privacy@yourcompany.com">privacy@yourcompany.com</a>.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }


import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      {/* Page Title Section */}
      <section className="page-title-area sky-blue-bg pt-160 pb-100">
         <div className="container">
           <div className="row justify-content-center">
             <div className="col-xl-6">
               <div className="page-title-wrapper text-center">
                 <h4 className="styled-text theme-color mb-30">Your Privacy Matters</h4>
                 
                 <h5 className="h1 page-title">Privacy Policy</h5>
               </div>
             </div>
           </div>
         </div>
       </section>

      {/* Privacy Policy Content */}
      <section className="privacy-policy-area py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="policy-content">
                <h3 className="fw-bold mb-4">Introduction</h3>
                <p className="text-muted mb-4">
                  At [Your Company Name], safeguarding your privacy is fundamental to our values. This Privacy Policy explains what data we collect, how we use it, and your rights regarding your personal information.
                </p>

                <h4 className="fw-bold mt-5 mb-3">1. Information We Collect</h4>
                <ul className="text-muted list-unstyled mb-4">
                  <li>• Personal data: Name, email address, payment details.</li>
                  <li>• Usage data: IP address, browser type, and activity logs.</li>
                </ul>

                <h4 className="fw-bold mt-5 mb-3">2. How We Use Your Information</h4>
                <p className="text-muted mb-4">
                  We use your data to deliver and improve our services, process transactions, and ensure compliance with legal obligations.
                </p>

                <h4 className="fw-bold mt-5 mb-3">3. Your Rights</h4>
                <p className="text-muted mb-4">
                  You have the right to access, correct, and delete your personal information. Contact us at{" "}
                  <a href="mailto:privacy@yourcompany.com" className="text-primary fw-bold">
                    privacy@yourcompany.com
                  </a>{" "}
                  to exercise your rights.
                </p>

                <h4 className="fw-bold mt-5 mb-3">4. Updates to This Policy</h4>
                <p className="text-muted">
                  We may periodically update this policy. Changes will be communicated via our website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
