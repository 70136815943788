import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoutes() {
  
  const storedToken = localStorage.getItem("token");


  return storedToken ? <Outlet /> : <Navigate to="/login" />;
}
