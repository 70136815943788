import React from "react";
import "./ServiceCards.css";
import { Link } from "react-router-dom";

const ServiceCards = ({ title, children, img, service }) => {
  return (
    <div className="card border rounded-lg p-4 h-100 bg-white">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          className="border border-secondary rounded-circle d-flex justify-content-center align-items-center"
          style={{ width: "160px", height: "160px" }}
        >
          <img
            src={img}
            alt="Card cap"
            className="img-fluid"
            style={{ maxWidth: "90px", borderRadius: "100%" }}
          />
        </div>
        <h3 className="mb-3 mt-3 text-center text-capitalize">{title}</h3>
        <p className="text-center text-muted">{children}</p>

        <Link to={`/quote/${service}`} className="proceed-btn mt-4 mb-25">
          <button className="outline-btn">
            Request a Quote{" "}
            <img
              src="assets/img/icon/right-arrow.svg"
              alt=""
              style={{ color: "#fff" }}
            />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCards;
